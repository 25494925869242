import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import createPlugin from "logrocket-vuex";
import LogRocket from "logrocket";

import config from "./config";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: `${config.namespace}__state`,
    }),
    createPlugin(LogRocket),
  ],
  state: {
    loggedIn: false,
    user: null,
    submerchant: null,
    submerchantUser: null,
  },
  mutations: {
    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    SET_SUBMERCHANT(state, submerchant) {
      state.submerchant = submerchant;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_SUBMERCHANT_USER(state, submerchantUser) {
      state.submerchantUser = submerchantUser;
    },
  },
  actions: {
    login(context) {
      context.commit("SET_LOGGED_IN", true);
      // context.commit('SET_SUBMERCHANT', null);
      // context.commit('SET_USER', null);
      // context.commit('SET_SUBMERCHANT_USER', null);
    },
    logout(context) {
      context.commit("SET_LOGGED_IN", false);
      context.commit("SET_SUBMERCHANT", null);
      context.commit("SET_USER", null);
      context.commit("SET_SUBMERCHANT_USER", null);

      Vue.ls.remove("access_token_expires_at");
      Vue.ls.remove("refresh_token_expires_at");
      Vue.ls.remove("device_id");

      // call the logout endpoint - this will clear the httponly cookies
      Vue.axios.delete("/v1/tokens/all");
      Vue.router.push("/login");
    },
    userUpdated(context) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/v1/users/current")
          .then((response) => {
            context.commit("SET_USER", response.data);
            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
  },
});

store.watch(
  (state) => state.user,
  (newUser, oldUser) => {
    if (!newUser && store.state.loggedIn) {
      // The logout button won't be visible on mobile if there is no user
      // clearly something has gone wrong, so we log the user out.
      store.dispatch('logout');
    }

    if (newUser) {
      LogRocket.identify(newUser.id, {
        name: newUser.displayName,
        email: newUser.emailAddress,
      });
    }
  },
  { immediate: true }
);

export default store;
